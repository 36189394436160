<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="display-1 font-weight-bold mb-3">お知らせ 管理画面</h1>
      </v-col>
      <v-col cols="12">
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            clearable
            class="shrink"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-2"
            @click="newItem()"
          >
            新規
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="announcements"
          :loading="loading"
          :search="search"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.enabled`]="{ item }">
            <strong v-if="checkOutOfTerm(item)" class="amber--text">期間外</strong>
            <strong v-else-if="item.enabled" class="success--text">掲載中</strong>
            <strong v-else class="error--text">掲載停止</strong>
          </template>
          <template v-slot:[`item.start_datetime`]="{ item }">
            {{ item.start_datetime | moment }}
          </template>
          <template v-slot:[`item.end_datetime`]="{ item }">
            {{ item.end_datetime | moment }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>no data.</template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="form.title"
              label="タイトル"
              :rules="[v => !!v || '入力してください。']"
            ></v-text-field>

            <v-textarea
              v-model="form.content"
              label="内容"
              :rules="[v => !!v || '入力してください。']"
            ></v-textarea>

            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="form.start_datetime"
                  label="掲載開始日"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="shrink"
                ></v-text-field>
              </template>
              <v-date-picker
                :allowed-dates="allowedStartDates"
                no-title
                @input="menuStartDate = false"
                v-model="form.start_datetime"
              ></v-date-picker>
            </v-menu>

            <v-row>
              <v-col cols="8">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="form.end_datetime"
                      clearable
                      label="掲載終了日"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="shrink"
                      :disabled="checkNoneEnd"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :allowed-dates="allowedEndDates"
                    no-title
                    @input="menuEndDate = false"
                    v-model="form.end_datetime"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="checkNoneEnd"
                  label="指定なし"
                  @change="changeNoneEnd"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-switch v-model="form.enabled">
              <template v-slot:label>
                <div>
                  掲載ステータス:
                  <strong v-if="form.enabled" class="success--text">掲載する</strong>
                  <strong v-else class="error--text">掲載停止</strong>
                </div>
              </template>
            </v-switch>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="editedId === -1 ? post() : put()"
          >
            保存
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">削除確認</span>
        </v-card-title>
        <v-card-text>
          このお知らせを削除します。よろしいですか？
          <div class="grey lighten-3 my-3 pa-2 font-weight-black">
            {{ form.title }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteItemConfirm"
          >
            削除
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.value"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar.value = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    headers: [
      { text: 'ID', value: 'id'},
      { text: 'タイトル', value: 'title'},
      { text: 'ステータス', value: 'enabled'},
      { text: '掲載開始日', value: 'start_datetime'},
      { text: '掲載終了日', value: 'end_datetime'},
      { text: '操作', value: 'actions', sortable: false },
    ],
    announcements: [],
    loading: false,
    dialog: false,
    dialogDelete: false,
    search: '',
    defaultItem: {
      title: '',
      content: '',
      start_datetime: null,
      end_datetime: null,
      enabled: true,
    },
    form: {
      title: '',
      content: '',
      start_datetime: null,
      end_datetime: null,
      enabled: true,
    },
    editedId: -1,
    valid: true,
    menuStartDate: false,
    menuEndDate: false,
    snackbar: {
      value: false,
      color: "",
      text: "",
    },

    checkNoneEnd: true,
  }),
  computed: {
    formTitle () {
      return this.editedId === -1 ? '新規登録' : '編集'
    },
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value).format('YYYY/MM/DD');
      } else {
        return '指定なし'
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch () {
      this.$authAxios
        .get(`/api/admin/announcements/`)
        .then(response => {
          this.announcements = response.data
        })
    },
    newItem () {
      this.form = Object.assign({}, this.defaultItem)
      this.checkNoneStart = true
      this.checkNoneEnd = true
      this.editedId = -1
      this.form.start_datetime = moment().format('YYYY-MM-DD')
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })
    },
    editItem (item) {
      this.form = Object.assign({}, item)
      this.form.start_datetime = moment(this.form.start_datetime).format('YYYY-MM-DD')
      if (this.form.end_datetime) {
        this.form.end_datetime = moment(this.form.end_datetime).format('YYYY-MM-DD')
        this.checkNoneEnd = false
      } else {
        this.checkNoneEnd = true
      }
      this.editedId = item.id
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })
    },
    deleteItem (item) {
      this.form = Object.assign({}, item)
      this.editedId = item.id
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.$authAxios
      .delete(`/api/admin/announcements/${this.editedId}/`)
      .then(() => {
        this.snackbar = {
          value: true,
          color: "success",
          text: "削除成功",
        }
        this.fetch()
        this.closeDelete()
      }).catch((err) => {
        console.log(err)
        this.snackbar = {
          value: true,
          color: "error",
          text: "削除失敗",
        }
      })
    },
    close () {
      this.dialog = false
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.form = Object.assign({}, this.defaultItem)
        this.editedId = -1
      })
    },
    post () {
      if (this.$refs.form.validate()) {
        this.$authAxios
        .post(`/api/admin/announcements/`, this.getVariables())
        .then(() => {
          this.snackbar = {
            value: true,
            color: "success",
            text: "登録成功",
          }
          this.fetch()
          this.close()
        }).catch((error) => {
          if (error.message.includes('duplicate key')) {
            this.snackbar = {
              value: true,
              color: "error",
              text: "すでに登録されています",
            }
          } else {
            this.snackbar = {
              value: true,
              color: "error",
              text: "登録失敗",
            }
          }
        })
      }
    },
    put () {
      if (this.$refs.form.validate()) {
        this.$authAxios
        .put(`/api/admin/announcements/${this.editedId}/`, this.getVariables())
        .then(() => {
          this.snackbar = {
            value: true,
            color: "success",
            text: "登録成功",
          }
          this.fetch()
          this.close()
        }).catch((error) => {
          if (error.message.includes('duplicate key')) {
            this.snackbar = {
              value: true,
              color: "error",
              text: "すでに登録されています",
            }
          } else {
            this.snackbar = {
              value: true,
              color: "error",
              text: "登録失敗",
            }
          }
        })
      }
    },
    allowedStartDates(val) {
      if(this.endDate) {
        return val <= this.endDate
      } else {
        return true
      }
    },
    allowedEndDates(val) {
      if(this.startDate) {
        return val >= this.startDate
      } else {
        return true
      }
    },
    formattedStartDate() {
      if (this.form.start_datetime) {
        let start = new Date(this.form.start_datetime)
        start.setHours(0,0,0,0)
        return start.toISOString()
      } else {
        return null
      }
    },
    formattedEndDate() {
      if (this.form.end_datetime) {
        let end = new Date(this.form.end_datetime)
        end.setHours(23,59,59,999)
        return end.toISOString()
      } else {
        return null
      }
    },
    getVariables() {
      let form = Object.assign({}, this.form)
      form.start_datetime = this.formattedStartDate()
      form.end_datetime = this.formattedEndDate()
      return form
    },
    checkOutOfTerm(item) {
      if (item.end_datetime) {
        if (moment().isBetween(moment(item.start_datetime), moment(item.end_datetime))) {
          return false
        } else {
          return true
        }
      } else {
        if (moment().isAfter(moment(item.start_datetime))) {
          return false
        } else {
          return true
        }
      }
    },
    changeNoneEnd(v) {
      if (v) {
        this.form.end_datetime = null
      }
    }
  }
};
</script>